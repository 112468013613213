import React, { useEffect } from "react";
import { logout } from "./user.service";
function Logout(props) {
  useEffect(() => {
    logout().then(async (res) => {
      await localStorage.clear();
      window.open(res.data.data.ssoRedirectUrl, "_self");
    });
  }, []);

  return <></>;
}

export default Logout;
