import React, { useEffect, useState } from 'react';
import FileUploadComponent from './FileUploadComponent';
import "./Dfp.css";

const FileUpload = () => {
  const [formData, setFormData] = useState({
    key: '',
    expiry: '',
    targeted: false,
    file: null,
  });



  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to track button disabled status


  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case 'key':
        if (value.trim() === '') {
          return 'Key is required';
        } else if (value.trim().length < 4) {
          return 'Key must be at least 4 characters';
        } else {
          return '';
        }
      case 'expiry':
        return value === '' ? 'Expiry is required' : '';
      default:
        return '';
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    setFormData((prev) => ({
      ...prev,
      [name]: fieldValue,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: validateField(name, fieldValue),
    }));
  };

  const handleSubmit = () => {
    const { key, expiry, file } = formData;

    const newErrors = {
      key: validateField('key', key),
      expiry: validateField('expiry', expiry),
      file: validateField('file', file),
    };

    setErrors(newErrors);
    if (Object.values(newErrors).some((error) => error !== '')) return;
    const payload = {
      fileName: file?.name,
      adid: key,
      initiatedBy: localStorage.getItem('userId'),
      targeted: formData.targeted,
      ttl: expiry,
    };



    fetch(process.env.REACT_APP_ADTECH_WCF_URL + "base/v1/process", {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        "auth-token": localStorage.getItem('authToken'),
        "Content-Type": "application/json"
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('File uploaded successfully:', data);
        setResponse(data);
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      });
  };

  useEffect(() => {
    // Enable button only when key, expiry, and file have values
    if (formData.key && formData.expiry && formData.file) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [formData]);
  return (
    <div>
      <div className="center-block" style={{ textAlign: 'center' }}>
        <h4>File Upload</h4>
      </div>

      <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <div className={"form-item"}>
            <label>Key<sup className='required'>*</sup></label>
            <input
              type="text"
              name="key"
              value={formData.key}
              onChange={handleChange}
              placeholder="Enter Key"
              required={true}
            />
            {errors.key && <p style={{ color: 'red' }}>{errors.key}</p>}
          </div>
        </div>
        <div className="col-md-6">
          {/* Expiry Field */}
          <div className={"form-item"}>
            <label>Expiry<sup className='required'>*</sup></label>
            <input
              type="number"
              name="expiry"
              value={formData.expiry}
              placeholder="Enter Expiry in days"
              onChange={handleChange}
            />
            {errors.expiry && <p style={{ color: 'red' }}>{errors.expiry}</p>}
          </div>
        </div>
      </div>
      <div className="row m-0 mt-30 mr-20">

        <div className="col-md-6">
          <FileUploadComponent formData={formData} setFormData={setFormData} errors={errors} setErrors={setErrors} />
        </div>
        <div className="col-md-6">
          {/* Targeted Field */}
          <div className={"form-item"}>
            <label>Targeted :</label>
            <input
              type="checkbox"
              name="targeted"
              className='checkbox'
              checked={formData.targeted}
              onChange={handleChange}
            />
            {formData.targeted && <div className="eligible">Eligible for Target</div>}
          </div>
        </div>
      </div>


      {/* Upload Base Button */}
      <div className={"form-item"}>
        <input
          type="button"
          value="Process Base"
          className="btn btn-sm mt-20 ml-20"
          style={{ backgroundColor: "rgb(76 135 92)" }}
          onClick={handleSubmit}
          disabled={isButtonDisabled} 
        />
      </div>


      {response && (
        <div className="response">
          <h4>Response:</h4>
          <pre>{JSON.stringify(response, null, 2)}</pre> {/* Convert response to prettified JSON string */}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
