import React, { useState, useCallback, useEffect } from 'react';
import "./Dfp.css"

const JobDetails = () => {
  const [jobId, setJobId] = useState('');
  const [result, setResult] = useState({ error: '', jobData: null });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to track button disabled status

  const handleSubmit = useCallback(() => {
    if (!jobId.trim()) {
      setResult({ error: 'Job ID is required.', jobData: null });
      return;
    }

    // Reset error and jobData before fetch
    setResult({ error: '', jobData: null });

    let fetchUrl = process.env.REACT_APP_ADTECH_WCF_URL + "job/v1/status/"
    // Fetch job details
    fetch(`${fetchUrl}${jobId}`, {
      headers: {
        "auth-token": localStorage.getItem('authToken')
      }
    })
      // fetch(process.env.REACT_APP_ADTECH_WCF_URL + "/ut/job/v1/status/nnn",)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch job details.');
        }
        return response.text().then((text) => {
          if (!text) {
            throw new Error('Job details not found.');
          }
          try {
            return JSON.parse(text);
          } catch (error) {
            throw new Error('Invalid JSON response.');
          }
        });
      })
      .then((data) => setResult({ error: '', jobData: data }))
      .catch((error) => setResult({ error: error.message, jobData: null }));
  }, [jobId]);

  useEffect(() => {
    // Enable button only when jobId has a value
    if (jobId.trim()) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [jobId]);

  return (
    <div>

      <div className="center-block" style={{ textAlign: 'center' }}>
        <h4>Job Details</h4>
      </div>

      <div className={"form-content"}>
        {/* Job ID Input Field */}
        <div className='jobId'>
          <label>Job ID<sup className='required'>*</sup></label>
          <input
            type="text"
            value={jobId}
            onChange={(e) => setJobId(e.target.value)}
          />
        </div>

        {/* Submit Button */}
        <div className='jobId'>
          <input
            type="button"
            value="Get Job Details"
            className="btn btn-sm mt-20 ml-20"
            style={{ backgroundColor: "rgb(76 135 92)" }}
            onClick={handleSubmit}
            disabled={isButtonDisabled} // Disable button if jobId is empty
          />
        </div>

      </div>
      {/* Display Errors or Job Data */}
      <div style={{ marginTop: '20px' }}>
        {result.error && <p style={{ color: 'red' }}>{result.error}</p>}
        {result.jobData && (
          <div>
            <h4>Job Details:</h4>
            <pre>{JSON.stringify(result.jobData, null, 2)}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobDetails;
