import { useForm } from 'react-hook-form';
import "../User.css"
import React, { useState } from 'react'
import { saveUserDetails } from '../user.service'
import { SAVE_USER_ENDPOINT, UPDATE_USER_ENDPOINT } from '../../Common Components/service.urls';
import { DOB_PATTERN, PHONE_NO_PATTERN } from '../../constants/RegularExpressions';

function RegisterUser(props) {

    const { register, handleSubmit, reset } = useForm();
    const [response, setResponse] = useState({ msg: "", isError: false });
    const[readonly, setReadOnly] = useState("read")

    let id = localStorage.getItem('userId');
    const onSubmit = (data) => {
        let method = typeof id !== "undefined" && id !== null ? "PUT" : "POST"
        let endPoint = (method === "POST") ? SAVE_USER_ENDPOINT : UPDATE_USER_ENDPOINT
        saveUserDetails(data, method, endPoint)
            .then(res => {
                setResponse({
                    msg: res.data,
                    isError: false,
                });
                reset();
            })
            .catch(err => {
                if (typeof err.response !== "undefined")
                    setResponse({ msg: err.response.data.error, isError: true })
                else
                    setResponse({ msg: "Oops! Something is wrong", isError: true })
            })
    }

    return (
        <div>

            <div className='register-box'>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className='form-group'>
                        <label htmlFor='userId'>UserId</label>
                        <input type="text" value={id} className="form-control" readonly={readonly} onFocus={(e)=>{setReadOnly(false)}}  id="userId" {...register('userId')} />
                       
                    </div>
                    <div className='form-group'>
                        <label htmlFor='dob'>DOB</label>
                        <input type="text" placeholder='Ex: 01-01-2022' pattern={DOB_PATTERN} title='Invalid dob'  className="form-control" id="dob" {...register('dob')} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='firstName'>First Name</label>
                        <input type="text" className="form-control" id="firstName"  {...register('firstName')} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='lastName'>Last Name</label>
                        <input type="text" className="form-control" id="lastName"  {...register('lastName')} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='company'>Company</label>
                        <input type="text" className="form-control" id="company"  {...register('company')} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='mailId'>Mail Id</label>
                        <input type="text" className="form-control" id="mailId"  {...register('mailId')} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='phoneNo'>PhoneNo</label>
                        <input type="text" className="form-control" placeholder='Ex : 9999988888' id="phoneNo"  pattern={PHONE_NO_PATTERN} title='Not a valid mobile number' {...register('phoneNo')} />
                    </div>
                    {
                        (typeof id == "undefined" || id == null) &&
                        <div>
                            <div className='form-group'>
                                <label htmlFor='password'>Password</label>
                                <input type="password" className="form-control" id="password" {...register('password')} />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='confirmPassword'>Confirm Password</label>
                                <input type="password" className="form-control" id="confirmPassword" {...register('confirmPassword')} />
                            </div>
                        </div>
                    }
                    <div className={response.isError ? "text-danger" : "text-success"}>
                        <b>
                            <h5>{response.msg}</h5>
                        </b>
                    </div>
                     <button type='submit' className='btn button-22'>Submit</button>
                </form>
            </div>
        </div>
    )
}



export default RegisterUser
