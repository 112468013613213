import React, { useState, useRef } from 'react';
import './FileUpload.css'; // Import the CSS for styling

const FileUploadComponent = ({ onFileSelect, allowedFileTypes = ".csv", setFormData }) => {
  const [formData, setData] = useState({ file: null });
  const [uploadProgress, setUploadProgress] = useState(0); // Initialize to 0
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef();
  const [uploadStarted, setUploadStarted] = useState(false); // Flag to track if upload has started

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === 'text/csv') {
      // Set form data and clear file error
      setFormData((prev) => ({ ...prev, file: selectedFile }));
      setData((prev) => ({ ...prev, file: selectedFile }));
      setErrors((prev) => ({ ...prev, file: '' }));

      // Prepare form data for the API call
      const uploadData = new FormData();
      uploadData.append('file', selectedFile);
      uploadData.append('fileName', selectedFile.name);

      // Create a new XMLHttpRequest to monitor progress
      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable && uploadStarted) {
          const percentComplete = (event.loaded / event.total) * 100;
          setUploadProgress(percentComplete);
        }
      });

      xhr.addEventListener('load', () => {
        if (xhr.status === 200) {
          console.log('File uploaded successfully:', xhr.responseText);
          setUploadProgress(100);
          // Optionally handle success state
        } else {
          console.error('Error uploading file:', xhr.statusText);
          setErrors((prev) => ({ ...prev, file: 'Error uploading file. Please try again.' }));
          setUploadProgress(0); // Reset progress on error
        }
      });

      xhr.addEventListener('error', () => {
        console.error('Error uploading file:', xhr.statusText);
        setErrors((prev) => ({ ...prev, file: 'Error uploading file. Please try again.' }));
        setUploadProgress(0); // Reset progress on error
      });

      xhr.open('POST', `${process.env.REACT_APP_ADTECH_WCF_URL}base/upload`);
      xhr.setRequestHeader("auth-token", localStorage.getItem('authToken'));

      // Set uploadStarted to true after the preflight request
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          setUploadStarted(true);
        }
      };

      xhr.send(uploadData);
    } else {
      // Handle invalid file selection
      setErrors((prev) => ({ ...prev, file: 'Invalid file type. Please upload a CSV file.' }));
      setFormData((prev) => ({ ...prev, file: null }));
      setData((prev) => ({ ...prev, file: null }));
      fileInputRef.current.value = ''; 
    }
  };

  const removeFile = () => {
    setFormData((prev) => ({ ...prev, file: null }));
    setData({ file: null });
    setUploadProgress(0); // Reset progress
    fileInputRef.current.value = ''; // Clear input field
  };

  const reUploadFile = () => {
    setUploadProgress(0);
    fileInputRef.current.click(); // Trigger file input click for re-upload
  };

  return (
    <div className="file-upload form-item">
      <label>File<sup className='required'>*</sup></label>
      <div className='label-wrapper'>
      <input
        type="file"
        accept={allowedFileTypes}
        onChange={handleFileChange}
        ref={fileInputRef}
        className="file-upload-input custom-file-input"
        id="inputGroupFile01"
        style={{ display: formData.file ? 'none' : 'block' }} // Hide input if file is selected
      />
      <label className="custom-file-label" htmlFor="inputGroupFile01">
        {'Choose file'}
      </label>
      </div>

      {formData.file && (
        <div className="file-details">
          <div className="file-info">
            <div className="file-name">{formData.file.name}</div>
            <div className="file-progress">
              <div
                className="progress-bar"
                style={{ width: `${uploadProgress}%`, backgroundColor: errors.file ? 'red' : 'green' }}
              >
                {uploadProgress}%
              </div>
            </div>
          </div>
          <div className="file-actions">
            <i type="button" className="remove-file" onClick={removeFile}>x</i>
            <i type="button" className="re-upload-file" onClick={reUploadFile}>⟳</i>
          </div>
        </div>
      )}
      {errors.file && <p className="file-error">{errors.file}</p>}
    </div>
  );
};

export default FileUploadComponent;
